import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { Storage } from "@carescribe/utilities/src/Storage";

import { setCustomPromptDismissedAt } from "../../state";

/**
 * Saves the `customPromptDismissedAt` state into local storage each time it
 * changes
 */
export const setInLocalStorage = function* ({
  app,
}: {
  app: string;
}): SagaIterator<void> {
  yield takeEvery(
    setCustomPromptDismissedAt,
    function* ({ payload: customPromptDismissedAt }): SagaIterator<void> {
      const stateAsString: SagaReturnType<typeof JSON.stringify> = yield call(
        [JSON, "stringify"],
        { customPromptDismissedAt }
      );

      yield call(Storage.setItem, { key: "pwa", app }, stateAsString);
    }
  );
};
