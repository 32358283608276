import type { Document } from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  DictationMode,
  DictationStatus,
  InlineStyles,
} from "@talktype/types";

import { createSelector, createSlice } from "@reduxjs/toolkit";

import { documentIsEmpty, extractText } from "@carescribe/slate";
import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

import { startDictating } from "../sagas/actions";
import { emptyDocument } from "../utils/entities";

export type EditorState = {
  document: Document;
  dictationMode: DictationMode;
  dictationStatus: DictationStatus;
  styles: Record<InlineStyles, boolean>;
};

export type CombinedState = {
  editor: EditorState;
};

const initialState: EditorState = {
  document: emptyDocument,
  dictationMode: "talktype",
  dictationStatus: "inactive",

  styles: {
    bold: false,
    italic: false,
    underline: false,
  },
};

const setDocumentReducer = (
  state: EditorState,
  { payload }: PayloadAction<Document>
): void => {
  /**
   * Saving Slate editor's history without creating a deep copy
   * does not tend to go well. Leading to:
   *
   * - Redux errors relating to Immer's auto-freezing
   * See: https://github.com/reduxjs/redux-toolkit/discussions/1189
   *
   * - SerializableCheck errors
   */
  state.document = {
    ...payload,
    history: safeStructuredClone(payload.history),
  };
};

const dictationLoadingReducer = (state: EditorState): void => {
  state.dictationStatus = "loading";
};

const dictationStartedReducer = (state: EditorState): void => {
  state.dictationStatus = "active";
};

const dictationStoppedReducer = (state: EditorState): void => {
  state.dictationStatus = "inactive";
};

const setDictationModeReducer = (
  state: EditorState,
  { payload }: { payload: DictationMode }
): void => {
  state.dictationMode = payload;
};

const setActiveStylesReducer = (
  state: EditorState,
  { payload }: PayloadAction<Partial<Record<InlineStyles, boolean>>>
): void => {
  state.styles = { ...state.styles, ...payload };
};

const clearActiveStylesReducer = (state: EditorState): void => {
  state.styles = {
    bold: false,
    italic: false,
    underline: false,
  };
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setDocument: setDocumentReducer,
    dictationStarted: dictationStartedReducer,
    stopDictating: dictationStoppedReducer,
    setActiveStyles: setActiveStylesReducer,
    clearActiveStyles: clearActiveStylesReducer,
    setDictationMode: setDictationModeReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(startDictating, dictationLoadingReducer);
  },
});

export const {
  reducer,
  actions: {
    setDocument,
    dictationStarted,
    stopDictating,
    setActiveStyles,
    clearActiveStyles,
    setDictationMode,
  },
} = slice;

export const selectDictationStatus = (state: CombinedState): DictationStatus =>
  state.editor.dictationStatus;

export const selectDictationMode = (state: CombinedState): DictationMode =>
  state.editor.dictationMode;

export const selectDocument = (state: CombinedState): Document =>
  state.editor.document;

export const selectDocumentChildren = createSelector(
  [selectDocument],
  (document) => document.children
);

export const selectDocumentLength = createSelector(
  selectDocument,
  (document) => extractText(document).length
);

export const selectDocumentIsEmpty = createSelector(
  [selectDocument],
  documentIsEmpty
);

export const selectDocumentHistory = createSelector(
  [selectDocument],
  (document) => document.history
);

export const selectDocumentIsUndoable = createSelector(
  [selectDocumentHistory],
  (history) => history.undos.length > 0
);

export const selectDocumentIsRedoable = createSelector(
  [selectDocumentHistory],
  (history) => history.redos.length > 0
);

export const selectDocumentSelection = createSelector(
  [selectDocument],
  (document) => document.selection
);

export const selectDictating = createSelector(
  [selectDictationStatus],
  (dictationStatus) => dictationStatus === "active"
);

export const editorIsActive = createSelector(
  [selectDocumentIsEmpty, selectDictating],
  (empty, dictating) => !empty || dictating
);
