import type { PWAConfig } from "../../types/PWAConfig";
import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getFromLocalStorage } from "./getFromLocalStorage";
import { setInLocalStorage } from "./setInLocalStorage";

export const setUpStatePersistence = function* ({
  app,
}: Pick<PWAConfig, "app">): SagaIterator<void> {
  yield retry(function* (): SagaIterator<void> {
    yield call(getFromLocalStorage, { app });
    yield call(setInLocalStorage, { app });
  });
};
