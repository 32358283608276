import type { RootState } from "@talktype/store";
import type { LayoutProps } from "@talktype/ui/src/Layout";

import { connect } from "react-redux";

import { selectDrawerIsOpen } from "@talktype/preferences/src/reducers/";
import { Layout as Component } from "@talktype/ui/src/Layout";
import { Notifications } from "@talktype/ui/src/Notifications";

import { AppControls } from "./AppControls";
import { DictationControls } from "./DictationControls";
import { Drawer } from "./Drawer";
import { Editor } from "./Editor";
import { FormattingToolbar } from "./FormattingToolbar";
import { HeaderBar } from "./HeaderBar";
import { PWAInstallPrompt } from "./PWAInstallPrompt";
import { ToastsContainer } from "./ToastsContainer";

const mapStateToProps = (
  state: RootState
): Pick<
  LayoutProps,
  | "prompt"
  | "header"
  | "drawer"
  | "formattingToolbar"
  | "editor"
  | "dictationControls"
  | "notifications"
> => ({
  drawer: {
    component: <Drawer />,
    isOpen: selectDrawerIsOpen(state),
  },
  prompt: <PWAInstallPrompt />,
  header: (
    <HeaderBar style="default" colour="neutral">
      <AppControls />
    </HeaderBar>
  ),
  formattingToolbar: <FormattingToolbar />,
  editor: <Editor />,
  dictationControls: <DictationControls />,
  notifications: (
    <Notifications>
      <ToastsContainer />
    </Notifications>
  ),
});

export const Layout = connect(mapStateToProps)(Component);
