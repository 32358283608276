import type { Store } from "./types";
import type { Saga } from "redux-saga";

import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { addStoreToWindowForCypress } from "@carescribe/utilities/src/addStoreToWindowForCypress";
import { createLogger } from "@carescribe/utilities/src/log";

import { reducer } from "./reducer";

const { logError } = createLogger("Uncaught Saga Error");

/**
 * Sets up the Redux store and saga middleware.
 *
 * Ensures store is accessible via `window` when in Cypress environment.
 */
export const setUpStore = (rootSaga: Saga): Store => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (err, errorInfo) => logError(err, errorInfo),
  });

  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(
        sagaMiddleware
      ),

    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  addStoreToWindowForCypress(window, store);

  return store;
};
