import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";
import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";
import { Storage } from "@carescribe/utilities/src/Storage";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { setCustomPromptDismissedAt } from "../../state";
import { logError } from "../../utils/log";

const isValidCustomPromptDismissedAt = createGuard<{
  customPromptDismissedAt: string;
}>({
  customPromptDismissedAt: P.union(P.string, null),
});

/**
 * Gets stored `customPromptDismissedAt` state from local storage and sets it
 * in the redux store
 */
export const getFromLocalStorage = function* ({
  app,
}: {
  app: string;
}): SagaIterator<void> {
  const storedPWAState: SagaReturnType<typeof Storage.getItem> = yield call(
    Storage.getItem,
    { key: "pwa", app }
  );

  if (!storedPWAState) {
    yield put(setCustomPromptDismissedAt(null));
    return;
  }

  const [parsedState]: SagaReturnType<typeof StrictJSON.safeParse> = yield call(
    StrictJSON.safeParse,
    storedPWAState
  );

  if (isValidCustomPromptDismissedAt(parsedState)) {
    yield put(setCustomPromptDismissedAt(parsedState.customPromptDismissedAt));
  } else {
    yield put(setCustomPromptDismissedAt(null));
    yield call(
      logError,
      "Invalid PWA state in found in local storage:",
      parsedState
    );
  }
};
