import type { PWAConfig } from "../types/PWAConfig";
import type { SagaIterator } from "redux-saga";

import { all, call } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpCustomInstallPrompt } from "./custom-install-prompt/setUpCustomInstallPrompt";
import { setUpNativeInstallPrompt } from "./native-install-prompt/setUpNativeInstallPrompt";
import { setUpStatePersistence } from "./state-persistence/setUpStatePersistence";

export const setUpPWA = function* (config: PWAConfig): SagaIterator<void> {
  yield all(
    [
      setUpCustomInstallPrompt,
      setUpNativeInstallPrompt,
      setUpStatePersistence,
    ].map((saga) =>
      retry(function* () {
        yield call(saga, config);
      })
    )
  );
};
